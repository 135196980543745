import React, { Component, Fragment } from "react";
import { FormGroup, FormControl } from "react-bootstrap";
import { Storage } from "aws-amplify";
//import { CookieStorage } from "@aws-amplify/auth";

export default class ReturnFromState extends Component {
    constructor(props){
      super(props);
      
      this.state = {
        textblocks:{}
      }
    }
    componentDidMount() {
      window.addEventListener('resize', this.updateDimensions)
      console.log(this.props.parentState.content)
    }
    handleFileChange = event => {
      this.ReadFile(event.target);
      //this.props.files = event.target.files[0];
    }
    handleChange = event => {
      console.log(event, event.target);
    }
    RemoveMe(id) {
      this.props.RemoveContent(id);
    }
    ReturnFormFromState(v) {
        return (
          <div id="ReportWrapper">
            {
              v.map((d, i)=>{
                return (
                <div key={"obj_"+i} className="ReportObjectHolder">
                  <div id={"obj_"+d.time} className="ReportObject">
                    <p className="stamp">{new Date(d.time).toString()}</p>
                    <button className="removeButton" onClick={()=> {this.RemoveMe(d.time)}}>
                      <img src="/Images/icons/cancel.svg" alt="remove"/>
                    </button>
                    {this.GetMeAnObject(d, i)}
                  </div>
                </div>)
              })
            }
          </div>
        )
    }
    Conf(o) {
      if (o.Confidence > 70) {
        return o.Value;
      }
      return ""
    }
    BoundingRectClick(o, imgID) {
      var timecalled = 0;
      var desc;
      if (this.Conf(o.Gender)) {
        desc = o.Gender.Value + ", ";
      } else {
        desc = "Person, "
      }
      desc += "Age " + o.AgeRange.Low + "-" + o.AgeRange.High;
      for (var index in o) {
        if (index !== "AgeRange"
         && index !== "Smile"
         && index !== "Emotions"
         && index !== "MouthOpen"
         && index !== "Confidence"
         && index !== "Quality"
         && index !== "Pose"
         && index !== "BoundingBox"
         && index !== "Landmarks"
         && index !== "Gender"
         && index !== "EyesOpen") {
          if (this.Conf(o[index])) {
            if (timecalled === 0) {
              desc += " with "
              timecalled++;
            }
            desc += index.toLowerCase() + ", "
          };
        }
      }
      desc = desc.replace(/,\s*$/, "");
      if (timecalled >= 1) {
        desc = desc.replace(/,(?=[^,]*$)/, ' and')
      }
      var imgObject = document.getElementById(imgID);

      var bb = o.BoundingBox;
      var newImgBounds = this.getImagePortion(imgObject, bb.Width, bb.Height, bb.Left, bb.Top);

      var obj = {
        type : "person", 
        time : Date.now(), 
        val : {
          Name : '',
          Desc : desc,
          Img : imgObject.src,
          ImgBounds: newImgBounds
        }
      }
      this.props.AddContent(obj);
    }
    ReadFile(f) {
      var self = this;
      if (f.files[0]) {
        var FR= new FileReader();
        FR.addEventListener("load", function(e) {
          var _id = (f.parentElement.id);
          var newContent = self.props.parentState.content;
          for (var index in newContent) {
            if (("obj_" + newContent[index].time) === _id) {
              newContent[index].type = "camera";
              newContent[index].val = e.target.result;
              self.props.SwapContent(newContent[index]);
            }
            //return;
          }
        });
        FR.readAsDataURL( f.files[0] );
      }
    }
    
    getImagePortion(imgObj, newWidthPerc, newHeightPerc, startXPerc, startYPerc) {
      var oH = imgObj.naturalHeight;
      var __r = 1/((newHeightPerc*oH)/90);
      var _obj = {
        newHeight: ((__r) * oH + "px"),
        startXPerc: ((startXPerc + newWidthPerc/2)*100 +"%"),
        startYPerc: ((startYPerc + newHeightPerc/2)*100 +"%")
      }
      return _obj;
     }

    async GetImageFromStorage(d) {
        const attachmentURL = await Storage.vault.get(d.val);
        return attachmentURL;
    }
    OnLoadTest() {
      setTimeout(()=>{
        document.getElementById('PhotoAddInput').click();
      }, 10)
      
    }
    GetMeAnObject(d, i) {
      //console.log(d.mlfaces[0].BoundingBox);
    switch(d.type) {
        case "photo":
        return (
          <Fragment>
        <input id="PhotoAddInput" type="file" accept="image/*" 
         onChange={this.handleFileChange} /> 
         {this.OnLoadTest()}
         </Fragment>
        )
        //break;
        case "text":
        return (
        <FormGroup controlId={"text_"+d.time}>
            <FormControl
                className="contentClass"
                defaultValue={d.val}
                componentClass="textarea"
            />
            </FormGroup>
        )
        //break;
        case "audio":
        //console.log('add an Audio clip')
        break;
        case "place":
        return(
        <div className="maps">
          <textarea className="contentClass form-control" defaultValue={d.name?d.name:d.val.address.StreetAddress} />
          <div className="map"></div>
          <div className="pano"></div>
        </div>
        );
        case "camera":
        return(
          <div className="cameraBox">
            <textarea className="contentClass form-control"  defaultValue={d.Name?d.Name:"Image"}  />
            <img
            id={"IMG_"+d.time}
            crossOrigin="anonymous"
            className="FromCameraImage" alt="your output" 
            src={d.val} onLoad={this.FindHeight}></img>
          </div>
        )
        case "person":
        if (d.val === "TEST") {
          return (
            <h1>person</h1>
          )
        }
        return(
          <div className="PersonDiv">
            <div
            className = "person_image" 
            style={{
              backgroundSize:(d.val.ImgBounds.newHeight + " auto"),
              backgroundPositionX:d.val.ImgBounds.startXPerc,
              backgroundPositionY:d.val.ImgBounds.startYPerc,
              backgroundImage:"url("+d.val.Img+")"

            }}></div>
            <input placeholder="Name"></input>
            <textarea defaultValue={d.val.Desc} ></textarea>
          </div>
        )
        //break;
        default:
        break;
    }
    }
    GetFaceBox(d, i) {
      var imgID = "IMG_"+d.val.split("-")[0];
      if (this.props.parentState.MLfaces) {
        if (this.props.parentState.MLfaces[imgID]) {
          return(
            <div className="RedLineWrapper">{
              this.props.parentState.MLfaces[imgID].map((_obj, l) => {
                return (
                  <div key={"rect_"+i+"_"+l} className="PersonRedLineBox"
                    style={
                      {
                        left:_obj.BoundingBox.Left*100-2 + "%",
                        top: _obj.BoundingBox.Top*100-2 + "%", 
                        width:_obj.BoundingBox.Width*100+4 + "%", 
                        height:_obj.BoundingBox.Height*100+4 + "%"
                      }
                    }
                    onClick={()=>{this.BoundingRectClick(_obj, imgID)}} >
                  </div>
                )
              })
            }
          </div>
          )}
      }
    }

    render() {
        return(
            <Fragment>
                {console.log(this.props.parentState.content)}
                {this.ReturnFormFromState(this.props.parentState.content)}
            </Fragment>
        )
    }
}