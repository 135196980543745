import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import {  Navbar } from "react-bootstrap";
import "./App.css";
import Routes from "./Routes";
import { Auth } from "aws-amplify";
import DialogueBox from "./components/DialogueBox";
import SendDialogueContent from "./components/SendDialougeContent"

class App extends Component {
  constructor(props) {
    super(props);
    //set theme//
    document.querySelector('body').setAttribute("class", this.QS('thm'));
    this.state = {
      isAuthenticated: false,
      isAuthenticating: true,
      userModel: {
        "custom:avatar":""
      }
    };
  }
  QS(term) {
    var st = this.props.location.search;
    var loc = st.indexOf(term);
    if (loc < 0) {
      return null;
    }
    var newst = st.slice(loc + term.length + 1);
    var amp = newst.indexOf("&");
    if (amp > -1) {
      return newst.slice(0, amp);
    }
    return newst;
  }
  handleLogout = async event => {
    await Auth.signOut();
    this.userHasAuthenticated(false, "");
    this.props.history.push("/login");
  }

  async componentDidMount() {
    try {
      await Auth.currentSession();
      this.userHasAuthenticated(true);
    }
    catch(e) {
      if (e !== 'No current user') {
        console.log(e);
      }
    }
    this.setState({ isAuthenticating: false });
  }

  GetUserModel = async () => {
    var n = await Auth.currentUserInfo();
    this.setState({
      userModel: n.attributes
    })
  }

  userHasAuthenticated = async (authenticated) => {
    this.setState({ isAuthenticated: authenticated});
    if (authenticated) {
      this.GetUserModel();
    }
  }

  TriggerDialogue(tf) {
    this.setState({Dialogue:tf})
  }
  TurnOnDialogue =()=> {
    this.TriggerDialogue(true);
  }
  TurnOffDialogue =()=> {
    this.TriggerDialogue(false);
  }
  SendDialogueUI =()=> {
    return (
      <SendDialogueContent Payload = {this.state.Payload} Off={this.TurnOffDialogue}/>
    )
  }
  SendDialogueBoxUI() {
    return (
      <DialogueBox
      Content = {this.SendDialogueUI}
      LeftButtonClick={this.handleLogout}
      RightButtonClick = {this.TurnOffDialogue}
      ScreenClick = {this.TurnOffDialogue}
      Header = "Send Case Report"
      />
    )
  }

  LoggedInButton() {
    return (
      <Link className="UserButton"
        //onClick={this.TurnOnDialogue.bind(this)}
        to="/user">
        <img alt="User" src={"/Images/avatars/" + this.state.userModel["custom:avatar"]}></img>
        <span className="UserNameText">
          {this.state.userModel.email}
        </span>
      </Link>
      //<NavItem onClick={this.handleLogout}>Logout</NavItem>
    )
  }
  TriggerSendDialogue =(st)=> {
    this.setState({
      Dialogue: true,
      Payload : st
    })
  }
  NotLoggedInButton() {
    return(
      <div className="LoginSignupButton">
        <Link to="/signup">
          Signup
        </Link>
         <span> or </span> 
        <Link to="/login">
          Login
        </Link>
      </div>
    )
  }
  GetNavBar() {
    return (
      <Navbar fluid collapseOnSelect>
          <Navbar.Header>
            <Navbar.Brand>
              <Link to="/">
                <img alt="Hubstream Watcher Logo" className="SquareLogo" src="/Images/watcher.png"></img>
                <img alt="Hubstream Watcher Text Logo" className="TextLogo" src="/Images/fieldhawk_text.svg"></img>
              </Link>
            </Navbar.Brand>
            
            {this.state.isAuthenticated
              ? this.LoggedInButton()
              : this.NotLoggedInButton()
            }
          </Navbar.Header>
        </Navbar>
    )
  }

  render() {
    
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated,
      TriggerSendDialogue: this.TriggerSendDialogue,
      userModel: this.state.userModel,
      GetUserModel: this.GetUserModel,
      HandleLogout: this.handleLogout
    };

    return (
      !this.state.isAuthenticating &&
      <div className="App container">
        {this.QS("chrome") === "false" ? <div className="NavReplacementSpacer"></div> : this.GetNavBar()}
        {this.state.Dialogue ? this.SendDialogueBoxUI() : null}
        <Routes childProps={childProps} />
      </div>
    );
  }
}

export default withRouter(App);