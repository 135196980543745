import { Storage } from "aws-amplify";

export async function s3Upload(file, link, temp) {
    const filename = `${Date.now()}-${file.name}`;
    if (!temp) {
        const stored = await Storage.vault.put(filename, file, {
            contentType: file.type,
            metadata: {
                LINK: link
            }
        });
        return stored.key;
    } else {
        const stored = await Storage.put(filename, file, {
            contentType: file.type
        });
        return stored.key;
    }
}