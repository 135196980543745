import React, { useState, useEffect, Fragment } from "react";
import "./FormComponents.css";
var moment = require('moment-timezone');

//<ButtonToggle />
//<input type="radio" id="male" name="gender" value="male">
//<label for="male">Male</label><br>
//
//props: {Values:[Yes, No]}
export function ButtonToggle(props) {
    const [selected, setSelected] = useState(() => {
        return props.Data.Default
            ? props.Data.Default
            : "";
    });
    return (
        <div className="ToggleHolder">
            { props.Data.IsRequired ? <RequiredFieldMarker /> : null}
            <h4>{props.Data.Caption}</h4>
            {
                props.Data.Values.map((d, i) => {
                    return (
                        <div className={selected === d ? "toggle-button selected" : "toggle-button"}
                            key={"toggle_" + i}
                            onClick={() => {
                                if (d === selected) {
                                    setSelected(null)
                                } else {
                                    setSelected(d);
                                }

                            }} >
                            <label htmlFor={props.Data.SafeID + "-" + d}>{d}</label><br />
                            <input type="radio" id={props.Data.SafeID + "-" + d}
                                name={props.Data.SafeID} value={d}
                                checked={selected === d ? "checked" : ""} readOnly
                                required={props.Data.IsRequired} ></input>
                        </div>
                    )
                    //<div key={"toggle_"+i} className={selected===d?"selected":""} onClick={()=>setSelected(d)}> {d} </div>
                })
            }
        </div>
    )
}
export function TimeInput(props) {
    let date = new Date();
    const [txt, setTxt] = useState(() => {
        let formatted_time = date.getHours().toString().padStart(2, 0) + ":" + date.getMinutes().toString().padStart(2, 0);
        return props.Data.IsDefaultTimeCurrent
            ? formatted_time
            : ""
    });
    const tz = moment.tz(date, moment.tz.guess()).format('Z z');
    return (
        <div className="TextInputHolder">
            { props.Data.IsRequired ? <RequiredFieldMarker /> : null}
            <h4>{props.Data.Caption + " (" + tz + ")"}</h4>
            <input type="time" name={props.Data.SafeID} value={txt} onChange={e => { setTxt(e.target.value) }}
                required={props.Data.IsRequired} />
        </div>
    )
}
export function DateInput(props) {
    const [txt, setTxt] = useState(() => {
        let date = new Date();
        let formatted_date = date.getFullYear().toString() + '-' + (date.getMonth() + 1).toString().padStart(2, 0) + '-' + date.getDate().toString().padStart(2, 0);
        return props.Data.IsDefaultDateCurrent
            ? formatted_date
            : ""
    })
    return (
        <div className="TextInputHolder">
            { props.Data.IsRequired ? <RequiredFieldMarker /> : null}
            <h4>{props.Data.Caption}</h4>
            <input type="date"
                name={props.Data.SafeID}
                value={txt}
                onChange={e => { setTxt(e.target.value) }}
                required={props.Data.IsRequired} />
        </div>
    )
}
export function NumberInput(props) {
    const [txt, setTxt] = useState(() => {
        return props.Data.Default
            ? props.Data.Default
            : ""
    })
    return (
        <div className="TextInputHolder">
            { props.Data.IsRequired ? <RequiredFieldMarker /> : null}
            <h4>{props.Data.Caption}</h4>
            <input type="number" name={props.Data.SafeID} value={txt} onChange={e => { setTxt(e.target.value) }}
                required={props.Data.IsRequired} />
        </div>
    )
}

//<Text />
export function TextInput(props) {
    const [txt, setTxt] = useState(() => {
        return props.Data.Default
            ? props.Data.Default
            : ""
    })
    return (
        <div className="TextInputHolder">
            { props.Data.IsRequired ? <RequiredFieldMarker /> : null}
            <h4>{props.Data.Caption}</h4>
            <input type="text" name={props.Data.SafeID} value={txt} onChange={e => { setTxt(e.target.value) }}
                /*pattern={props.Data.ValidationRegEx?props.Data.ValidationRegEx:""}*/
                required={props.Data.IsRequired} />
        </div>
    )
}

//<ReadOnly />
export function ReadOnly(props) {
    const [txt, setTxt] = useState(() => {
        return props.Data.Value
            ? props.Data.Value
            : ""
    })
    return (
        <div className="TextInputHolder ReadOnly">
            { props.Data.IsRequired ? <RequiredFieldMarker /> : null}
            <h4>{props.Data.Caption}</h4>
            <input type="text" readOnly name={props.Data.SafeID} value={txt} onChange={e => { setTxt(e.target.value) }}
                /*pattern={props.Data.ValidationRegEx?props.Data.ValidationRegEx:""}*/
                required={props.Data.IsRequired} />
        </div>
    )
}
//<Price />

//<BigText />
export function TextareaInput(props) {
    const [txt, setTxt] = useState("");
    return (
        <div className="TextInputHolder">
            { props.Data.IsRequired ? <RequiredFieldMarker /> : null}
            <h4>{props.Data.Caption}</h4>
            <textarea name={props.Data.SafeID} type="text" value={txt} onChange={e => { setTxt(e.target.value) }}
                required={props.Data.IsRequired} />
        </div>
    )
}
//<Location />
export function PinDrop(props) {
    return (
        <div className="maps">
            { props.Data.IsRequired ? <RequiredFieldMarker /> : null}
            <h4>{props.Data.Caption}</h4>
            <textarea className="contentClass form-control" name={props.Data.SafeID} />
            <div className="map"></div>
            {/*<div className="pano"></div>*/}
        </div>
    )
}
//<ComboInput />
export function ComboInput(props) {
    return (
        <div className="TextInputHolder">
            { props.Data.IsRequired ? <RequiredFieldMarker /> : null}
            <h4>{props.Data.Caption}</h4>
            <input type="text" name={props.Data.SafeID} list={props.Data.SafeID}
                required={props.Data.IsRequired} />
            <datalist id={props.Data.SafeID}>
                {props.Data.ValueList.map((v, i) => {
                    return <option key={"op_" + i} value={v.Value} />
                })}
            </datalist>
        </div>
    )
}
//<Checkbox />
export function Checkbox(props) {
    const [onOff, setOnOff] = useState(false);
    return (
        <div className="checkbox-holder" onClick={e => setOnOff(!onOff)}>
            <input type="checkbox" name={props.Data.SafeID} id={props.Data.Caption} readOnly checked={onOff}
                required={props.Data.IsRequired} />
            {!props.Data.DataType === "YesNo"
                ? <span className="checkbox-label">{props.Data.Caption}</span>
                : <h4 className="checkbox-label">{props.Data.Caption}</h4>}
        </div>
    )
}

//<Dropdown />
export function ComboBox(props) {
    return (
        <div className="TextInputHolder">
            { props.Data.IsRequired ? <RequiredFieldMarker /> : null}
            <h4>{props.Data.Caption}</h4>
            <select name={props.Data.SafeID}>
                {props.Data.ValueList.map((v, i) => {
                    return <option key={"op_" + i}>{v.Value}</option>
                })}
            </select>
        </div>
    )
}
//<Multicheckbox />

//<Image />

//<Images />

//good for s3 temp storage of images
export function MultiImages(props) {
    const [FormImages, setFormImages] = useState([]);
    const caption = props.Data.Caption ? props.Data.Caption : "Click to add images";

    useEffect(() => {
        for (var i = 0; i < FormImages.length; i++) {
            var f = FormImages[i];
            CallThis(f);
        }
    }, [FormImages])

    function CallThis(f) {
        var FR = new FileReader();
        FR.addEventListener("load", (e) => {
            var n = f.name.replace(/[ .]/g, "");
            var imguploadname = Date.now() + "_" + f.name;
            var img_el = document.getElementById("img_" + n);
            img_el.src = e.target.result;
            img_el["data-image-name"] = imguploadname;
            //document.getElementById("input_"+ n).value = imguploadname;
        })
        FR.readAsDataURL(f)
    }

    function getFormImages() {
        var ar = [];
        for (let k = 0; k < FormImages.length; k++) {
            const element = FormImages[k];
            ar.push({ name: element.name });
        }
        return ar.map((d, i) => {
            return (
                <img id={"img_" + d.name.replace(/[ .]/g, "")} alt={d.name} key={"img_" + i} />
            )
        })
    }

    return (
        <div className="image-upload">
            { props.Data.IsRequired ? <RequiredFieldMarker /> : null}
            <label className="filebutton" htmlFor="file-input">
                {(FormImages && (FormImages.length > 0))
                    ? <div className="added-images">
                        {getFormImages()}
                    </div>
                    : caption
                }
            </label>
            <input name={props.Data.SafeID} onChange={e => setFormImages(e.target.files)} id="file-input" type="file" accept="image/*" multiple />
        </div>
    )
}

//good for base64 payload of images
export function MultiImagesBase64(props) {
    const [FormImages, setFormImages] = useState([]);
    const caption = props.Data.Caption ? props.Data.Caption : "Click to add images";

    useEffect(() => {
        for (var i = 0; i < FormImages.length; i++) {
            var f = FormImages[i];
            CallThis(f);
        }
    }, [FormImages])

    function CallThis(f) {
        var FR = new FileReader();
        FR.addEventListener("load", (e) => {
            document.getElementById("img_" + f.name.replace(/[ .]/g, "")).src = e.target.result;
            document.getElementById("input_" + f.name.replace(/[ .]/g, "")).value = e.target.result;
        })
        FR.readAsDataURL(f)
    }

    function getFormImages() {
        var ar = [];
        for (let k = 0; k < FormImages.length; k++) {
            const element = FormImages[k];
            ar.push({ name: element.name });
        }
        return ar.map((d, i) => {
            return (
                <Fragment>
                    <img id={"img_" + d.name.replace(/[ .]/g, "")} alt={d.name} key={"img_" + i} />
                    <textarea id={"input_" + d.name.replace(/[ .]/g, "")} name={"image_" + d.name.replace(/[ .]/g, "")} key={"input_" + i} className="hidden" />
                </Fragment>
            )
        })
    }

    return (
        <div className="image-upload">
            { props.Data.IsRequired ? <RequiredFieldMarker /> : null}
            <label className="filebutton" htmlFor="file-input">
                {(FormImages && (FormImages.length > 0))
                    ? <div className="added-images">
                        {getFormImages()}
                    </div>
                    : caption
                }
            </label>
            <input name={props.Data.SafeID} onChange={e => setFormImages(e.target.files)} id="file-input" type="file" accept="image/*" multiple />
        </div>
    )
}

function RequiredFieldMarker() {
    return (
        <span className="form-required">*</span>
    )
}