import React, { Component, Fragment } from "react";
import { API, Auth } from "aws-amplify";
import { Button } from "react-bootstrap";
import Avatars from '../data/avatar_map.json';
import Plans from '../data/Plans.json';
import "./User.css";

//import "./UserDialogueContent.css";

export default class User extends Component {
    constructor(props) {
        super(props);
        this.state = {
          Invitations: {List:[]}
        };
      }
    async componentDidMount() {
        try {
          var v = await this.GetInvitations();
          this.setState({
              Invitations: v
          })
        }
        catch(e) {
          if (e !== 'No current user') {
            console.log(e);
          }
        }
      
        this.setState({ isAuthenticating: false });
    }

    GetInvitations() {
        return API.get("notes", `/GetInvitations`);
    }

    MapInvitations() {
        return (
            this.state.Invitations.List.map((d,i)=> {
                return <li key={"invitation_"+i}>
                   <div className="InvitationName">
                       {d.Name}
                       <img src={d.Image} alt={d.Name}></img>
                   </div>
                </li>
            })
        )
    }

    MapIcons() {
        //console.log(this.props.userModel);
        return (
            Avatars.AvatarList.map((d,i)=> {
                return <li key={"icon_"+i} onClick={this.imageClicked}
                    className={d.file === this.props.userModel["custom:avatar"]?"current":""}>
                    <img src={"Images/avatars/"+d.file} alt={d.name} title={d.name}></img>
                </li>
            })
        )
    }
     imageClicked = async (s)=> {
        var _s = (s.target.parentElement.getAttribute('class'));
        var _ar = document.querySelectorAll('.DialogueBox ul.'+ _s +' li.current');
        for (let i = 0; i < _ar.length; i++) {
            const element = _ar[i];
            element.className="";
        }
        var li = s.target;
        li.className = "current";

        let user = await Auth.currentAuthenticatedUser();
        var obj = {}
        var st;
        if (_s === "CardSelector") {
           st = li.querySelector('h4').innerText;
          obj["custom:subscription-level"] = st;
        } else {
           st = li.querySelector('img').src;
          var loc = st.lastIndexOf('/') + 1;
          obj["custom:avatar"] = st.slice(loc);
        }
        let result = await Auth.updateUserAttributes(user, obj);
        if (result === "SUCCESS") {
            this.props.GetUserModel();
            //Get updated User and Set to State//;
        } 

    }
    MapPlans() {
        return(
            Plans.Plans.map((d,i)=> {
                return <li key={"plan_"+i} onClick={this.imageClicked}
                    className={d.Name === this.props.userModel["custom:subscription-level"]?"current":""}
                    >
                    <img src={"Images/plan_images/"+d.Image} alt={d.Name}></img>
                    <h4>{d.Name}</h4>
                    <span className="description">
                        {d.Description}
                    </span>
                    <span className="cost">
                        {d.Cost}
                    </span>
                    </li>
            })
        )
    }
    handleChange(e) {

        console.log(e);
        console.log(e.target);
    }
    ReturnSubAndInv() {
        return (
            <Fragment>
            <div className="page-section Plan-section">
                    <h3>My Subscription</h3>
                    <ul className="CardSelector">
                    {/*this.MapPlans()*/}
                    </ul>
                    <a className="CardSelectorLink" rel="noopener noreferrer"  href="https://www.hubstreamsoftware.com/watcher" target="_blank">
                        >  See a more in-depth plan comparison</a>
                </div>

                <div className="page-section Invitations-section">
                    <h3>My Invitations</h3>
                    <ul className="Invitations"> 
                        {/*this.MapInvitations()*/}
                    </ul>
                    <a className="CardSelectorLink black" rel="noopener noreferrer"  href="https://www.hubstreamsoftware.com/watcher-global-set" target="_blank">
                        >  Learn more about the global dataset</a>
                </div>
            </Fragment>
        )
    }
    render() {
        return (
            <div className="UserPage">
                <div className="page-section Additional-section">
                    <h3>Profile Information</h3>
                    <span className="profile-form">This information is used by companies to invite you to their datasets.</span>
                    <form>
                        <div className="form-group name-group">
                        <label>First Name</label>
                        <input  className="form-control name-field"  id="FirstName"
                        onChange={this.handleChange}
                        ></input>
                        </div>
                        <div className="form-group name-group">
                        <label>Last Name</label>
                        <input  className="form-control name-field"  id="LastName"
                        onChange={this.handleChange}
                        ></input>
                        </div>
                        <div className="form-group company-group">
                        <label>Company</label>
                        <input value={this.props.userModel["custom:company"]}   className="form-control company-field"  id="Company"
                        onChange={this.handleChange}
                        ></input>
                        </div>
                        <div className="form-group company-group">
                        <label>Address</label>
                        <input className="form-control company-field"  id="Company"  placeholder="Street Address"
                        onChange={this.handleChange}
                        ></input>
                            <div className="form-row">
                                <div className="col-7">
                                <input type="text" className="form-control" placeholder="City"></input>
                                </div>
                                <div className="col">
                                <input type="text" className="form-control" placeholder="State"></input>
                                </div>
                                <div className="col">
                                <input type="text" className="form-control" placeholder="Zip"></input>
                                </div>
                            </div>

                        </div>
                        <div className="form-group company-group">
                        <label>Linkedin profile</label>
                        <input type="textarea" className="form-control address-field"  id="Address"
                        onChange={this.handleChange}
                        ></input>


                        </div>
                        
                            
                        </form>
                </div>
                <div className="page-section Icons-section" >
                    <h3>My Icon</h3>
                    <ul className="iconSelector">
                        {this.MapIcons()}
                    </ul>
                </div>
                <div className="page-section Butotns-section">
                    <Button onClick={this.props.HandleLogout}>Logout</Button>
                </div>
          </div>
        )
    }
}
