import React, { Component } from "react";
import { API, Storage } from "aws-amplify";
import LoaderButton from "../components/LoaderButton";
import config from "../config";
import { s3Upload } from "../libs/awsLib";
import "./Notes.css";
import NoteHeaderButtons from "./NoteHeaderButtons";
import ReturnFromState from "./ReturnFromState";

export default class Notes extends Component {
  constructor(props) {
    super(props);
    this.AddContent = this.AddContent.bind(this);
    this.SwapContent = this.SwapContent.bind(this);
    this.RemoveContent = this.RemoveContent.bind(this);
    this.files = [];

    this.state = {
        isLoading: null,
        isDeleting: null,
        note: null,
        content: [],
        MLfaces: null,
        MLlabels: null,
        MLtext: null,
        attachmentURL: null,
        report: null
      };
  }
  componentDidUpdate() {
    this.HandleLocations();
  }
  async HandleLocations() {
    
    for (var index in this.state.content) {
      var id;
      if (this.state.content[index].type === "place") {
        //Google Maps needs to be called later.
        id = "#obj_" + this.state.content[index].time;
        var place = {lat:this.state.content[index].val.latitude, lng:this.state.content[index].val.longitude};
        var map = new window.google.maps.Map(document.querySelector(id + ' .maps .map'), {
          center: place,
          zoom: 16,
          //panControl: false,
          //gestureHandling: false,
          //disableDefaultUI: true,
          draggable: false,
          streetViewControl: false,
          disableDefaultUI:true
        });
        var panorama = new window.google.maps.StreetViewPanorama(
          document.querySelector(id + ' .maps .pano'), {
            position: place,
            pov: {
              heading: 34,
              pitch: 10
            },
            streetViewControl:false,
            disableDefaultUI: true
          });
      map.setStreetView(panorama);
      }
      else if (this.state.content[index].type === "camera" 
      && (this.state.content[index].val.indexOf('base64') < 0)) {
        id = "#obj_" + this.state.content[index].time;
        var img = document.querySelector(id + " img.FromCameraImage");
        if (img) {
          this.GetImageFromStorage(this.state.content[index].val ,img);
        }
      }
    };
  }
  
  AddPeopleFromFaces(content) {
    for (var index in content) {
      if (content[index].mlfaces) {
        for (var index2 in content[index].mlfaces) {
          var _obj = content[index].mlfaces[index2];
          var personObj = {
            time: Date.now(),
            type: "person",
            val:{
              Name:["",""],
              img:null,
              linkedTo:content[index].time,
              linkedToBB: _obj.BoundingBox
            }
          }
          content.push(personObj);
        }
      }
    }
    return content;
  }

  async componentDidMount() {
    try {
      const note = await this.getNote();
      //*
      for (var index in note.content) {
        if (note.content[index].type === "camera" && !note.content[index].mlfaces) {
          //WE ADD POTENTIAL ENTITIES
          //var mlfaces = await this.getMLFaces(note.content[index].val);
          //note.content[index].mlfaces = mlfaces.FaceDetections;
        };
      }
      //var newContent = this.AddPeopleFromFaces(note.content);
      //*/
      const { name, content, MLfaces, MLlabels, MLtext } = note;
      this.setState({
        note,
        name,
        content,
        MLfaces,
        MLlabels,
        MLtext
      });
    } catch (e) {
      console.log(e);
    }
  }

  async GetImageFromStorage(attachment, img) {
    var attachmentURL = await Storage.vault.get(attachment);
    img.src = attachmentURL;
  }

  //duplicate from NewNote
  AddContent(obj) {
    var newContent = this.state.content;
      console.log(newContent);
      newContent.push(obj);
      this.setState({
        content: newContent
      })
  }
  SwapContent(obj) {
    var newContent = this.state.content;
    for (var index in newContent) {
      if (obj.time === newContent[index].time) {
        newContent[index] = obj;
      }
    }
    this.setState({
      content:newContent
    });
  }
  RemoveContent(timestamp) {
    var newContent = this.state.content;
    for (var index in newContent) {
      if (timestamp === newContent[index].time) {
        newContent.splice(index, 1);
      }
    }
    this.setState({
      content:newContent
    });
  }
  //End duplicate code

  getNote() {
    return API.get("notes", `/notes/${this.props.match.params.id}`);
  }
  /*REMOVED
  getMLFaces(ImgID) {
    return API.get("notes", `/ML/faces/${ImgID}`)
  }
  //*/

  validateForm() {
    return this.state.content.length > 0;
  }
  
  formatFilename(str) {
    return str.replace(/^\w+-/, "");
  }
  
  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }
  
  handleFileChange = event => {
    this.file = event.target.files[0];
  }
  
  saveNote(note) {
    note.HubstreamGuid = "FAKEGUID";
    return API.put("notes", `/notes/${this.props.match.params.id}`, {
      body: note
    });
  }
  CleanData() {
    this.files = [];
    var ar = this.state.content;
    for (var index in ar) {
      if (ar[index].type === "text") {
        var txt = document.querySelector("#obj_"+ar[index].time + " textarea");
        ar[index].val = txt.value;
      }
      if (ar[index].type === "camera" || ar[index].type === "place") {
        ar[index].name = document.querySelector("#obj_"+ar[index].time + " textarea").value;
        console.log(ar[index])
        //ar[index].val = txt.value;
      }
      if (ar[index].type === "camera" && (ar[index].val.indexOf("base64") > -1)) {
        var img = ar[index].val;
        
        var block = img.split(";");
        var contentType = block[0].split(":")[1];
        var realData = block[1].split(",")[1];
        var blob = this.b64toBlob(realData, contentType, null, ar[index].name);
        var blob_obj = {
          blob: blob,
          timestamp: ar[index].time
        }
        this.files.push(blob_obj);
      }
      if (ar[index].type === "person") {
        if (ar[index].val.Img) {
          ar[index].val.Img = ar[index].val.Img.split("?")[0].split("/")[5];
        }
        
      }
    }
    return ar;
  }
  b64toBlob(b64Data, contentType, sliceSize, name) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;
    name = name || "Hi-Upload";
    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }
    var blob = new Blob(byteArrays, {type: contentType});
    blob.name = name;
    return blob;  
  }

  handleSubmit = async event => {
    if (event) { event.preventDefault() };
    this.setState({ isLoading: true });
    //get vals for all text objects
    //convert base64 images to a file for s3 - only for ML work
    var newContent = this.CleanData();
    for (var i in this.files) {
      if (this.files[i].blob && this.files[i].blob.size > config.MAX_ATTACHMENT_SIZE) {
        alert(`Please pick a file smaller than ${config.MAX_ATTACHMENT_SIZE/1000000} MB.`);
        return;
      }
      try {
        var attachment = this.files[i].blob
        ? await s3Upload(this.files[i].blob, this.props.match.params.id, false)
        : null;
        for (var index in newContent) {
          if (newContent[index].time === this.files[i].timestamp) {
            newContent[index].val = attachment
          }
        }
      }
      catch (e) {
        console.log(e);
        this.setState({isLoading: false});
      }
    }    

    /*
    if (this.file && this.file.size > config.MAX_ATTACHMENT_SIZE) {
      alert(`Please pick a file smaller than ${config.MAX_ATTACHMENT_SIZE/1000000} MB.`);
      return;
    }
    //*/
    try {
      await this.saveNote({
        content: newContent
      });
      this.props.history.push("/");
    } catch (e) {
      console.log(e);
      this.setState({ isLoading: false });
    }
  }
  
  
  deleteNote() {
    return API.del("notes", `/notes/${this.props.match.params.id}`);
  }


  
  handleDelete = async event => {
    event.preventDefault();
  
    const confirmed = window.confirm(
      "Are you sure you want to delete this note?"
    );
  
    if (!confirmed) {
      return;
    }
  
    this.setState({ isDeleting: true });
  
    try {
      await this.deleteNote();
      this.props.history.push("/");
    } catch (e) {
      console.log(e);
      this.setState({ isDeleting: false });
    }
  }
  
  render() {
    return (
      <div className="Notes">
        <div className="header">
          <h1>{this.state.name}</h1>
        </div>
        <br />
        <NoteHeaderButtons parentState={this.state} ParentAddContent={this.AddContent} TriggerSendDialogue={this.props.TriggerSendDialogue}/>
        <ReturnFromState parentState={this.state} SwapContent={this.SwapContent} RemoveContent={this.RemoveContent} AddContent={this.AddContent}/>
        {this.state.note &&
          <form onSubmit={this.handleSubmit}>
            <LoaderButton
              block
              bsStyle="primary"
              bsSize="large"
              disabled={!this.validateForm()}
              type="submit"
              isLoading={this.state.isLoading}
              text="Save"
              loadingText="Saving…"
            />
            <LoaderButton
              block
              bsStyle="danger"
              bsSize="large"
              isLoading={this.state.isDeleting}
              onClick={this.handleDelete}
              text="Delete"
              loadingText="Deleting…"
            />
          </form>}
      </div>
    );
  }
}