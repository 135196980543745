import React, { Component, Fragment } from "react";
import { ListGroup, ListGroupItem } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { API } from "aws-amplify";
import { Link } from "react-router-dom";
import "./Home.css";

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ErrorMessage: "Checking Hubstream Connection",
      isLoading: true,
      notes: [],
      invitations: {Targets:[]}
    };
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }
  
    try {
      const notes = await this.notes();
      const invitations = await this.getServerInvitations();
      if(invitations.Targets.message) {
        this.setState({ notes, ErrorMessage:invitations.Targets.message });
      } else {
        this.setState({ notes, invitations, ErrorMessage:null });
      }
    } catch (e) {
      this.setState({ErrorMessage:"No Hubstream Server Connection"})
    }
  
    this.setState({ isLoading: false });
  }
  
  notes() {
    return API.get("notes", "/notes");
  }
  getServerInvitations() {
    return API.get("notes", `/GetInvitations`);
  }
  async inviteClicked(d) {
    console.log(d);
    //var uid = await this.GetUUID();
    //console.log(uid);
    //create case in aws
    try {
      var r = await this.createNote({
      name: d.Name,
      noteId: d.Guid,
        content: []
      });
      console.log(r);
      this.props.history.push("/notes/"+d.Guid);
    } catch (e) {
        console.log(e);
        alert('problem with the create API')
        this.setState({ isLoading: false });
    }
  //*/
}
  GetUUID() {
    return API.post("notes", "/uid");
  }
  createNote(note) {
    return API.post("notes", "/notes", {
      body: note
    });
  }

  FigureOutIcons(note, content) {
    var _cam = false;
    var _img = false;
    var _loc = false;
    var _txt = false;
    var _hub = false;
    for (var index in content) {
      if (content[index].type === "camera") {_cam = true;}
      if (content[index].type === "image") {_img = true;}
      if (content[index].type === "text") {_txt = true;}
      if (content[index].type === "place") {_loc = true;}
    }
    if (this.state.invitations.Targets) {
      for (let index = 0; index < this.state.invitations.Targets.length; index++) {
        const element = this.state.invitations.Targets[index];
        if(element.Guid === note.noteId) {
          _hub = this.state.invitations.Image;
        }
      }
    }
    
    return (
      <div className="ReportIcons">
        {_hub?<img src={_hub} alt="install"/>:null}
        {_cam?<img src="Images/icons/picture.svg" alt="media"></img>:null}
        {_img?<img src="Images/icons/picture.svg"  alt="media"></img>:null}
        {_txt?<img src="Images/icons/newspaper.svg"  alt="note"></img>:null}
        {_loc?<img src="Images/icons/placeholder.svg"  alt="location"></img>:null}
      </div>
    )
  }

  renderNotesList(notes) {
    return notes.map(
      (note, i) =>
        <LinkContainer className="bigNote"
              key={note.noteId}
              to={`/notes/${note.noteId}`}
            >
              <li>
              <h4>{note.name.trim().split("\n")[0]}</h4>
              {this.FigureOutIcons(note, note.content)}
                <p>{"Created: " + new Date(note.createdAt).toLocaleString()}</p>
              </li>
        </LinkContainer>
        
    );
  }
  renderInvitesList(notes) {
    if (notes.Targets) {
      return notes.Targets.filter(d=>{
        var tf = true;
        for (let index = 0; index < this.state.notes.length; index++) {
          const element = this.state.notes[index];
          if(element.noteId === d.Guid) {tf = false};
        }
        return tf;
      }).map(
        (note, i) =>
          <div className="bigNote invite"
                key={"invite_" + i}
                onClick={(e)=>{this.inviteClicked(note)}}
              >
                <span className="invite-tag">New Assignment</span>
                <li>
  
                <h4>{note.Name.trim().split("\n")[0]}</h4>
                <div className="ReportIcons">
                  <img src={notes.Image} alt={notes.Name}/>
                </div>
                </li>
          </div>
          
      );
    } else {
      return null;
    }
    
  }
  renderLander() {
    return (
      <Fragment>
      <div className="lander">
        <h1 className="HomeText">The AI powered field app for investigators.</h1>
      </div>
      <br/>
      <Link to="/signup" className="btn btn-success btn-lg btn-xxl">
            Try it now!
      </Link>
      


      </Fragment>
    );
  }
  renderNotes() {
    return (
      <Fragment>
      <LinkContainer className="newButton"
              key="new"
              to="/notes/new"
            >
              <ListGroupItem>
                <img alt="Plus sign" src="Images/icons/add.svg" />
                <span>New Report</span>
              </ListGroupItem>
            </LinkContainer>
      <div className="notes">
        
        <ListGroup>
        {!this.state.isLoading && this.renderInvitesList(this.state.invitations)}
          {!this.state.isLoading && this.renderNotesList(this.state.notes)}
        </ListGroup>
      </div>
      </Fragment>
    );
  }

  render() {
    return (
      <div className="Home">
        {this.state.ErrorMessage?<h4 className="error-message">{this.state.ErrorMessage}</h4>:null}
        {this.props.isAuthenticated ? this.renderNotes() : this.renderLander()}
      </div>
    );
  }
}