import React, { Component } from "react";
import LoaderButton from "../components/LoaderButton";
import config from "../config";
import { API } from "aws-amplify";
import { s3Upload } from "../libs/awsLib";
import "./NewNote.css";
import NoteHeaderButtons from "./NoteHeaderButtons";
import ReturnFromState from "./ReturnFromState";

export default class NewNote extends Component {
  constructor(props) {
    super(props);
    this.AddContent = this.AddContent.bind(this);
    this.SwapContent = this.SwapContent.bind(this);
    this.RemoveContent = this.RemoveContent.bind(this);
    this.files = [];
    this.state = {
      ReportName: "",
      isLoading: null,
      content: []
    };
  }
  OpenStream;
  GivenHeight = 0;

  componentDidUpdate() {
    for (var index in this.state.content) {
      if (this.state.content[index].type === "place") {
        //Google Maps needs to be called later.
        var id = "#obj_" + this.state.content[index].time;
        var place = {lat:this.state.content[index].val.latitude, lng:this.state.content[index].val.longitude};
        var map = new window.google.maps.Map(document.querySelector(id + ' .maps .map'), {
          center: place,
          zoom: 14
        });
        var panorama = new window.google.maps.StreetViewPanorama(
          document.querySelector(id + ' .maps .pano'), {
            position: place,
            pov: {
              heading: 34,
              pitch: 10
            }
          });
      map.setStreetView(panorama);
      } else if(this.state.content[index].type === "photo") {
        document.querySelector("#obj_" + this.state.content[index].time + " input").click();
      }
    };
  }

  validateForm() {
    return ((this.state.content.length > 0)  &&  this.state.ReportName.length>0);
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  CleanData() {
    this.files = [];
    var ar = this.state.content;
    for (var index in ar) {
      if (ar[index].type === "text") {
        var txt = document.querySelector("#obj_"+ar[index].time + " textarea");
        ar[index].val = txt.value;
      }
      if (ar[index].type === "camera") {
        var img = ar[index].val;
        var block = img.split(";");
        var contentType = block[0].split(":")[1];
        //var contentType = "text/plain";
        var realData = block[1].split(",")[1];
        var blob = this.b64toBlob(realData, contentType);
        var blob_obj = {
          blob: blob,
          timestamp: ar[index].time
        }
        this.files.push(blob_obj);
      }
    }
    return ar;
  }
  
  b64toBlob(b64Data, contentType, sliceSize) {
    //*
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }
  var blob = new Blob(byteArrays, {type: contentType});
  blob.name = "HI-Upload";
  return blob;
  //*/
}
async GetUUID() {
  return await API.post("notes", "/uid");
}
  handleSubmit = async event => {
    event.preventDefault();
    this.setState({ isLoading: true });

    var uid = await this.GetUUID();
    //get vals for all text objects
    //*convert base64 images to a file for s3 - only for ML work
    var newContent = this.CleanData();
    var newName = document.getElementById('ReportName').value;
    for (var i in this.files) {
      if (this.files[i].blob && this.files[i].blob.size > config.MAX_ATTACHMENT_SIZE) {
        alert(`Please pick a file smaller than ${config.MAX_ATTACHMENT_SIZE/1000000} MB.`);
        return;
      }
      try {
        var attachment = this.files[i].blob
        ? await s3Upload(this.files[i].blob, uid, false)
        : null;
        for (var index in newContent) {
          if (newContent[index].time === this.files[i].timestamp) {
            newContent[index].val = attachment
          }
        }
      }
      catch (e) {
        console.log(e);
        alert('problem with this attachment')
        this.setState({isLoading: false});
      }
    }
    try {
        await this.createNote({
        name: newName,
        noteId:uid,
        content: newContent
        });
        this.props.history.push("/");
    } catch (e) {
        console.log(e);
        alert('problem with the create API')
        this.setState({ isLoading: false });
    }
    //*/
  }

  createNote(note) {
    return API.post("notes", "/notes", {
      body: note
    });
  }

  
  //duplicate code on Note.js
  SwapContent(obj) {
    var newContent = this.state.content;
    for (var index in newContent) {
      if (obj.time === newContent[index].time) {
        newContent[index] = obj;
      }
    }
    this.setState({
      content:newContent
    });
  }
  AddContent(obj) {
    var newContent = this.state.content;
      newContent.push(obj);
      this.setState({
        content: newContent
      })
  }
  RemoveContent(timestamp) {
    var newContent = this.state.content;
    for (var index in newContent) {
      if (timestamp === newContent[index].time) {
        newContent.splice(index, 1);
      }
    }
    this.setState({
      content:newContent
    });
  }
  //End duplicate


  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  render() {
    return (
      <div className="NewNote">
        <NoteHeaderButtons parentState={this.state} ParentAddContent={this.AddContent}  TriggerSendDialogue={false} />
        <div className="header">
          <form>
            <label>Case Name</label><br/>
            <input id="ReportName"
            onChange={this.handleChange}
              ></input>
          </form>
        </div>
        <br />
        <ReturnFromState parentState={this.state} SwapContent={this.SwapContent} RemoveContent={this.RemoveContent}/>
        
        <form onSubmit={this.handleSubmit}>
          <LoaderButton
            block
            bsStyle="primary"
            bsSize="large"
            disabled={!this.validateForm()}
            type="submit"
            isLoading={this.state.isLoading}
            text="Create"
            loadingText="Creating…"
          />
        </form>

      </div>
    );
  }
}