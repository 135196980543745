import React, { Component, Fragment } from "react";
import {GetLocation} from "../libs/locLib";
export default class NoteHeaderButtons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Cameras: [],
      CurrentCamera: 0
    }
  }
  possibleFaces = ["environment","user"]
  CameraIDs = [];
    Clicked(type) {
        if (type === "place") {
          GetLocation(this.props.ParentAddContent);
        } else if (type === "camera") {
          this.GetImageFromCamera();
        } else {
          var obj = {"type":type, val: "", time: Date.now()};
          this.props.ParentAddContent(obj);   
        }
    }

    TakePicture() {
        var canvas = document.querySelector('#BigCamera .canvas');
        var video = document.querySelector('#BigCamera .video');
        var width = window.innerWidth;
        var height = this.GivenHeight;
    
        var context = canvas.getContext('2d');
        canvas.width = width;
        canvas.height = height;
        context.drawImage(video, 0, 0, width, height);
        var data = canvas.toDataURL('image/png');
        var obj = {
          type: 'camera', time: Date.now(),
          val: data
        }
        this.StopCamera();
        this.props.ParentAddContent(obj);
    }
    SwitchCamera() {
      this.StopRecording();
      var newCamera = this.state.CurrentCamera;
      newCamera++;
      if (newCamera >= this.state.Cameras.length) {
        newCamera = 0;
      }
      this.setState({
        CurrentCamera: newCamera
      })

      setTimeout(()=>{
        this.GetImageFromCamera();
      }, 15)
      
    }

    CheckCameras() {
      var self = this;
      var ar = [];
      navigator.mediaDevices.enumerateDevices().then(function(devices) {
        for (var i = 0; i !== devices.length; ++i) {
            if (devices[i].kind === 'videoinput' && (devices[i].label.indexOf(' IR ')<0)) {
                ar.push(devices[i].deviceId);
            }
        }
        self.setState({Cameras: ar});
      });
    }

    StopCamera() {
        this.StopRecording();
        document.getElementById('BigCamera').style.display = "none";
    }
    StopRecording() {
      this.OpenStream.getTracks().forEach(track => track.stop())
    }
    GetImageFromCamera() {
        var self = this;
        var constraints = {
            audio: false,
            video: {
              facingMode: this.possibleFaces[this.state.CurrentCamera],
              deviceId: {exact: this.state.Cameras[this.state.CurrentCamera]},
              width: { ideal: 4096 },
              height: { ideal: 2160 } 
          }
        }
      var width = window.innerWidth;
      var streaming = false;
      var video = document.querySelector('#BigCamera .video');
      video.setAttribute('autoplay', '');
      video.setAttribute('muted', '');
      video.setAttribute('playsinline', '');
      navigator.mediaDevices.getUserMedia(constraints)
      .then(function(stream) {
          self.OpenStream = stream;
          document.getElementById('BigCamera').style.display = "block";
          video.srcObject = stream;
          video.play();
      })
      .catch(function(err) {
          console.log(err);
      });
      video.addEventListener('canplay', function(ev){
          if (!streaming) {
          self.GivenHeight = video.videoHeight / (video.videoWidth/width);
          streaming = true;
          }
      }, false);
    }

    render() {
        return(
            <Fragment>
            <div id="BigCamera">
          <div className="camera">
            <video className="video">Video stream not available.</video>
            <button id="startbutton"
              onClick={(e)=>{ this.TakePicture(); e.preventDefault(); }}>
              <img src="/Images/icons/photo-camera (2).svg" 
            alt="camera"></img></button>
            <button id="stopbutton"
              onClick={(e)=>{ this.StopCamera(); }}>
              <img src="/Images/icons/cancel.svg" 
            alt="cancel"></img></button>
            <button id="switchbutton"
              style={(this.state.Cameras.length<2)?{display:"none"}:null}
              onClick={()=>{ this.SwitchCamera(); }}>
              <img src="/Images/icons/curved-arrow.svg" 
            alt="swap"></img></button>
          </div>
          <canvas className="canvas">
          </canvas>
        </div>

        
        <div className="buttons">
          <button
            onClick={(e) => {this.Clicked("photo")}} ><img src="/Images/icons/picture.svg" 
            alt="media"></img></button>
          {/*<button
            style={(this.state.Cameras.length<1)?{display:"none"}:null}
            onClick={(e) => {this.Clicked("camera")}} ><img src="/Images/icons/photo-camera (2).svg" 
          alt="camera"></img></button>*/}
          <button
            onClick={(e) => {this.Clicked("text")}}  ><img src="/Images/icons/newspaper.svg"
            alt="note"></img></button>
          {/*<button><img src="/Images/icons/microphone.svg" 
            onClick={(e) => {this.Clicked("audio")}}  alt="audio clip"></img></button>*/}
          <button
            onClick={(e) => {this.Clicked("place")}}  ><img src="/Images/icons/placeholder.svg" 
            alt="location information"></img></button>
              
            {this.props.TriggerSendDialogue?<button className="stb" style={this.props.parentState.content.length < 1? {display:"none"}:null}
              onClick={() => {this.props.TriggerSendDialogue(this.props.parentState.note) } }>
            <img src="/Images/icons/message.svg" 
                alt="Send to brand"></img>
            </button>:null}
        </div>
        </Fragment>
        )
    }
}