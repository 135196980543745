export default {
    MAX_ATTACHMENT_SIZE: 50000000,
    s3: {
      REGION: "us-east-2",
      BUCKET: "hubstream-investigator-uploads"
    },
    apiGateway: {
      REGION: "us-east-2",
      URL: "https://d881jw0ie0.execute-api.us-east-2.amazonaws.com/prod"
    },
    cognito: {
      REGION: "us-east-2",
      USER_POOL_ID: "us-east-2_H8RDwJL5y",
      APP_CLIENT_ID: "76k40nhluf0hlt1tt0ep3nmce4",
      IDENTITY_POOL_ID: "us-east-2:7e037023-9f8d-4373-b13d-353d5e301768"
    }
  };