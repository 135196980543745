import React, { Component } from "react";
import { Button } from "react-bootstrap";
import "./DialogueBox.css";

export default class DialogueBox extends Component {
    constructor(props) {
        super(props);
        console.log(props);
    }
    DialogueBodyClicked(e) {
        e.stopPropagation();
    }
    render() {
        return (
        <div className="DialogueScreen" onClick={this.props.ScreenClick}>
        <div className="DialogueBox" onClick={this.DialogueBodyClicked.bind(this)}>
          <div className="DialogueBox-footer">
              <h2>{this.props.Header}</h2>
              <div className="DialogueContent">{this.props.Content()}</div>
              <div className="DialogueFooter">
              <Button onClick={this.props.RightButtonClick}>Cancel</Button>
              </div>
          </div>
        </div>
        </div>
        )
    }
}
