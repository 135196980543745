const J = {
    "Name": "Investigation Form",
    "Icon": "https://media.licdn.com/dms/image/C560BAQEstBw0xdYIFQ/company-logo_200_200/0?e=2159024400&v=beta&t=to4rCAw-1pfF8oS3f0nMqVE8yUW4XrteWlS3xFqgXkU",
    "DarkColor": "#1ea7b7",
    "LightColor": "white",
    "Groups": [
      {
        "Name": "Category",
        "IsCollapsable": false,
        "IsExpanded": true,
        "IsVisible": false,
        "Elements": [
          {
            "ID": "Name",
            "SafeID": "Name",
            "Caption": "Name",
            "Type": "TextBox",
            "DataType": "Text",
            "IsRequired": true,
            "ValidationRegEx": "/^[\\s\\t\\r\\n]*\\S+/ig",
            "MaximumLength": 100,
            "IsMultiColumn": true,
            "IsSearchIdentifier": true,
            "IsBulkEditEnabled": false,
            "ParentMetadataID": 0,
            "MetadataID": 0
          },
          {
            "ID": "Status",
            "SafeID": "Status",
            "Caption": "Status",
            "Type": "ComboBox",
            "DataType": "Text",
            "IsRequired": true,
            "ValidationRegEx": "/^[\\s\\t\\r\\n]*\\S+/ig",
            "DefaultValue": "Open",
            "ValueList": [
              {
                "ID": "Importing",
                "Value": "Importing"
              },
              {
                "ID": "Pending",
                "Value": "Pending"
              },
              {
                "ID": "Open",
                "Value": "Open"
              },
              {
                "ID": "Closed",
                "Value": "Closed"
              },
              {
                "ID": "Referred",
                "Value": "Referred"
              }
            ],
            "IsBulkEditEnabled": true,
            "ParentMetadataID": 0,
            "MetadataID": 141
          },
          {
            "ID": "Synopsis",
            "SafeID": "Synopsis",
            "Caption": "Synopsis",
            "Type": "MultiLineTextBox",
            "DataType": "LongText",
            "IsMultiColumn": true,
            "IsBulkEditEnabled": true,
            "ParentMetadataID": 0,
            "MetadataID": 0
          },
          {
            "ID": "Private",
            "SafeID": "Private",
            "Caption": "Private",
            "Type": "CheckBox",
            "DataType": "YesNo",
            "IsRequired": false,
            "ValidationRegEx": "/^[\\s\\t\\r\\n]*\\S+/ig",
            "DefaultValue": "False",
            "IsBulkEditEnabled": true,
            "ParentMetadataID": 0,
            "MetadataID": 0
          },
          {
            "ID": "Investigation Result",
            "SafeID": "InvestigationResult",
            "Caption": "Investigation Result",
            "Type": "ComboBox",
            "DataType": "Text",
            "DefaultValue": "Information only",
            "ValueList": [
              {
                "ID": "",
                "Value": ""
              },
              {
                "ID": "Information only",
                "Value": "Information only"
              },
              {
                "ID": "Unsolved-Offender Unknown",
                "Value": "Unsolved-Offender Unknown"
              },
              {
                "ID": "Unsolved-Offender Known",
                "Value": "Unsolved-Offender Known"
              },
              {
                "ID": "Solved-Charges laid",
                "Value": "Solved-Charges laid"
              },
              {
                "ID": "Solved-Investigation Continuing Offender Known",
                "Value": "Solved-Investigation Continuing Offender Known"
              },
              {
                "ID": "Solved-Conviction",
                "Value": "Solved-Conviction"
              },
              {
                "ID": "Solved-No Charges",
                "Value": "Solved-No Charges"
              },
              {
                "ID": "Solved-acquittal, withdrawn, stayed",
                "Value": "Solved-acquittal, withdrawn, stayed"
              },
              {
                "ID": "Complaint Unfounded",
                "Value": "Complaint Unfounded"
              },
              {
                "ID": "Solved-no charges offender known",
                "Value": "Solved-no charges offender known"
              },
              {
                "ID": "Investigation Continuing",
                "Value": "Investigation Continuing"
              }
            ],
            "IsBulkEditEnabled": true,
            "ParentMetadataID": 0,
            "MetadataID": 16
          },
          {
            "ID": "Priority",
            "SafeID": "Priority",
            "Caption": "Priority",
            "Type": "ComboBox",
            "DataType": "Text",
            "DefaultValue": "Routine",
            "ValueList": [
              {
                "ID": "",
                "Value": ""
              },
              {
                "ID": "High Risk",
                "Value": "High Risk"
              },
              {
                "ID": "Routine",
                "Value": "Routine"
              },
              {
                "ID": "Low Risk",
                "Value": "Low Risk"
              },
              {
                "ID": "Unknown",
                "Value": "Unknown"
              }
            ],
            "IsBulkEditEnabled": true,
            "ParentMetadataID": 0,
            "MetadataID": 439
          },
          {
            "ID": "Incident Type",
            "SafeID": "IncidentType",
            "Caption": "Incident Type",
            "Type": "ComboBoxInput",
            "DataType": "Text",
            "ValueList": [
              {
                "ID": "",
                "Value": ""
              },
              {
                "ID": "optionONE",
                "Value": "asdfasdf"
              },
              {
                "ID": "New Value List",
                "Value": "New Value List"
              }
            ],
            "IsMultiColumn": true,
            "IsBulkEditEnabled": true,
            "ParentMetadataID": 0,
            "MetadataID": 521
          },
          {
            "ID": "Informational",
            "SafeID": "Informational",
            "Caption": "Informational",
            "Type": "CheckBox",
            "DataType": "YesNo",
            "IsRequired": false,
            "ValidationRegEx": "/^[\\s\\t\\r\\n]*\\S+/ig",
            "DefaultValue": "False",
            "IsBulkEditEnabled": true,
            "ParentMetadataID": 0,
            "MetadataID": 0
          },
          {
            "ID": "Secondary",
            "SafeID": "Secondary",
            "Caption": "Secondary Investigation",
            "Type": "NullCheckBox",
            "DataType": "YesNo",
            "IsRequired": false,
            "IsBulkEditEnabled": true,
            "ParentMetadataID": 0,
            "MetadataID": 0
          }
        ]
      },
      {
        "Name": "Source",
        "IsCollapsable": false,
        "IsExpanded": true,
        "IsVisible": true,
        "Elements": [
          {
            "ID": "Custom Status",
            "SafeID": "CustomStatus",
            "Caption": "Custom Status",
            "Type": "ComboBox",
            "DataType": "Text",
            "ValueList": [
              {
                "ID": "",
                "Value": ""
              },
              {
                "ID": "Importing",
                "Value": "Importing"
              },
              {
                "ID": "Pending",
                "Value": "Pending"
              },
              {
                "ID": "Open",
                "Value": "Open"
              },
              {
                "ID": "Closed",
                "Value": "Closed"
              },
              {
                "ID": "Referred",
                "Value": "Referred"
              }
            ],
            "IsBulkEditEnabled": true,
            "ParentMetadataID": 0,
            "MetadataID": 607
          },
          {
            "ID": "Source Organization",
            "SafeID": "SourceOrganization",
            "Caption": "Source Organization",
            "Type": "TextBox",
            "DataType": "Text",
            "IsMultiColumn": true,
            "IsBulkEditEnabled": true,
            "ParentMetadataID": 0,
            "MetadataID": 0
          },
          {
            "ID": "Source Report Number",
            "SafeID": "SourceReportNumber",
            "Caption": "Source Report Number",
            "Type": "TextBox",
            "DataType": "Text",
            "IsMultiColumn": true,
            "IsBulkEditEnabled": true,
            "ParentMetadataID": 0,
            "MetadataID": 0
          },
          {
            "ID": "ContactName",
            "SafeID": "ContactName",
            "Caption": "Source Contact Person",
            "Type": "TextBox",
            "DataType": "Text",
            "IsMultiColumn": true,
            "IsBulkEditEnabled": true,
            "ParentMetadataID": 0,
            "MetadataID": 0
          },
          {
            "ID": "ContactEmail",
            "SafeID": "ContactEmail",
            "Caption": "Source Contact Email",
            "Type": "TextBox",
            "DataType": "Text",
            "IsMultiColumn": true,
            "IsBulkEditEnabled": true,
            "ParentMetadataID": 0,
            "MetadataID": 0
          },
          {
            "ID": "Source Contact Title",
            "SafeID": "SourceContactTitle",
            "Caption": "Source Contact Title",
            "Type": "TextBox",
            "DataType": "Text",
            "IsMultiColumn": true,
            "IsBulkEditEnabled": true,
            "ParentMetadataID": 0,
            "MetadataID": 0
          },
          {
            "ID": "ContactPhone",
            "SafeID": "ContactPhone",
            "Caption": "Source Contact Phone",
            "Type": "TextBox",
            "DataType": "Text",
            "IsMultiColumn": true,
            "IsBulkEditEnabled": true,
            "ParentMetadataID": 0,
            "MetadataID": 0
          },
          {
            "ID": "Date Received",
            "SafeID": "DateReceived",
            "Caption": "Date Received",
            "ToolTip": "Enter a date in the format YYYY-MM-DD, such as 2013-05-15.",
            "Type": "Date",
            "DataType": "Date",
            "IsRequired": true,
            "ValidationRegEx": "/^[\\s\\t\\r\\n]*\\S+/ig",
            "DateFormat": "yyyy-MM-dd",
            "IsDefaultDateCurrent": true,
            "IsBulkEditEnabled": true,
            "ParentMetadataID": 0,
            "MetadataID": 0
          },
          {
            "ID": "Time Received",
            "SafeID": "TimeReceived",
            "Caption": "Time Received",
            "ToolTip": "Enter a valid time in the format HH:mm, such as 09:19",
            "Type": "Time",
            "DataType": "Time",
            "Is24HourTime": true,
            "IsMultiColumn": true,
            "IsBulkEditEnabled": true,
            "ParentMetadataID": 0,
            "MetadataID": 0
          },
          {
            "ID": "Source Date Made Available",
            "SafeID": "SourceDateMadeAvailable",
            "Caption": "Source Date Made Available",
            "ToolTip": "Enter a valid date in the format yyyy-MM-dd, such as 2020-04-09",
            "Type": "Date",
            "DataType": "Date",
            "DateFormat": "yyyy-MM-dd",
            "IsBulkEditEnabled": true,
            "ParentMetadataID": 0,
            "MetadataID": 0
          },
          {
            "ID": "CaseNumber",
            "SafeID": "CaseNumber",
            "Caption": "Case Number",
            "Type": "TextBox",
            "DataType": "Text",
            "MaximumLength": 4000,
            "IsMultiColumn": true,
            "IsBulkEditEnabled": true,
            "ParentMetadataID": 0,
            "MetadataID": 0
          },
          {
            "ID": "TotalMediaFiles",
            "SafeID": "TotalMediaFiles",
            "Caption": "Total Media Files",
            "ToolTip": "Enter a valid number.",
            "Type": "TextBox",
            "DataType": "Number",
            "IsBulkEditEnabled": true,
            "ParentMetadataID": 0,
            "MetadataID": 0
          },
          {
            "ID": "TotalPrecategorized",
            "SafeID": "TotalPrecategorized",
            "Caption": "Total Precategorized",
            "ToolTip": "Enter a valid number.",
            "Type": "TextBox",
            "DataType": "Number",
            "IsBulkEditEnabled": true,
            "ParentMetadataID": 0,
            "MetadataID": 0
          }
        ]
      }
    ]
  }
  export default J;