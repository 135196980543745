
import React, { Component } from "react";
import { API, Storage } from "aws-amplify";
import { Button } from "react-bootstrap";
//import NewNote from "../containers/NewNote";
//import "./UserDialogueContent.css";

export default class SendDialogueContent extends Component {
    constructor(props) {
        super(props);
        console.log(props);
        this.state = {
          IsLoading: true,
          Invitations: {List:[]}
        };
    }

    async componentDidMount() {
        try {
          var v = await this.GetInvitations();
          console.log(v);
          var el = this.CheckTargets(v.Targets);
          if (el) {
            this.setState({
                Environment: v
              //Invitations: v
            })
            //SEND
            await this.CleanData(this.props.Payload.content);
            //console.log(p);
            this.SendToEntity(v, el);
          } else {
              this.setState({
                  Environment: {
                      Name:"No assignments found."
                  }
              })
          }
        }
        catch(e) {
          if (e !== 'No current user') {
            console.log(e);
          }
        }
    }
    CheckTargets(targets) {
        console.log(targets, this.props.Payload.noteId);
        for (let index = 0; index < targets.length; index++) {
            const element = targets[index];
            if (element.Guid === this.props.Payload.noteId) {
                return element;
            }
        }
        return false;
    }
    GetInvitations() {
        return API.get("notes", `/GetInvitations`);
    }

    Send = async (d) => {
        this.setState({IsLoading:true})
        var p = await this.CleanData(this.props.Payload.content);
        let myInit = {
            body: {
                key: d.Key,
                target: "New",
                data: p,
                name: this.props.Payload.name
            }
        }
        API.post("notes", "/SendToHubstream", myInit).then(response => {
            this.props.Off();
        }).catch(error => {
            console.log(error.response)
        });
    }

    SendToEntity = async (d,d2) => {
        //this.setState({IsLoading:true})
        console.log(d, d2);
        var p = await this.CleanData(this.props.Payload.content);
        let myInit = { 
            body: {
                key : d.Key,
                target: d2.Guid,
                data: p
            }
        }
        
        API.post("notes", "/SendToHubstream", myInit).then(response => {
            //this returns the GUID created by Hubstream//
            //console.log(response.GUID);
            this.setState({IsLoading:false})
            this.props.Off();
        }).catch(error => {
            this.setState({IsLoading:false})
            console.log(error);
        })
    }
    async CleanData(payload) {
        var new_payload = [];
        for (let index = 0; index < payload.length; index++) {
            const element = payload[index];
            if (element.type === "camera") {
                var _url = await Storage.vault.get(element.val);
                var b64 = await this.b64FromURLAsync(_url);
                element.b64val = b64.replace("data:image/png;base64,", "");
                new_payload.push(element);
            } else {
                new_payload.push(element);
            }
        }
        return new_payload;
    }

    b64FromURLAsync(attachmentURL) {
        return new Promise(function(resolve, reject){
            let image  = new Image();
            image.crossOrigin = 'Anonymous';
            image.addEventListener('load', function() {
                let canvas = document.createElement('canvas');
                let context = canvas.getContext('2d');
                canvas.width = image.width;
                canvas.height = image.height;
                context.drawImage(image, 0, 0);
                try {
                  resolve(canvas.toDataURL('image/png'));
                } catch (err) {
                  reject("error");
                }
            });
        image.src = attachmentURL;
        });
    }

    MapInvitations() {
        return (
            this.state.Invitations.List.map((d,i)=> {
                if (d.Name === "Email") {
                    return (
                        <form key="EmailForm" className="email-form">
                        <div className="form-group email-form-group">
                            <label>Email a PDF report</label>
                            <br/>
                            <input className="form-control company-field" placeholder="Company or email" id="Company"
                            onChange={this.handleChange}
                            ></input>
                            <Button className="SendButton">Send</Button>
                        </div>
                    </form> )
                } else {
                    return(
                        <div className="FullButton"  key={"invitation_"+i}>
                            <div className="LogoBlock">
                            <img src={d.Image} alt={d.Name}></img> 
                            <h3>{d.Name}</h3></div>
                            {d.New?<Button className="SmallButton New"
                            onClick={(e)=> {this.Send(d)}}
                            >Add to New</Button>:null}
                            {d.Targets.map((d2, k)=>{
                                return(<Button key={"sendto_"+k} onClick={(e2)=>{this.SendToEntity(d,d2)}} className="SmallButton">{d2.Name}</Button>)
                            })}
                        </div>
                        
                    )
                }
            })
        )
    }
    Loader() {
        return(
            <div className="floater">
                <h3>{this.state.Environment?"Sending to "+this.state.Environment.Name :"Checking"}...</h3>
                <img src="/Images/icons/message.svg" alt="message sends" className="floating-message" />
            </div>
        )
    }

    render() {
        return(
            <div className="SendDialogue">
                {this.state.IsLoading?this.Loader():this.MapInvitations()}        
            </div>
        )
    }
}