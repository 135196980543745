export async function ReverseGeoLookup(place) {
    //place = {lat:0, lng:0}//
    //var self = this;
    var geocoder = new window.google.maps.Geocoder();
    var addressObject = await CodeAddress(place, geocoder);
    return addressObject;
  }
function GetAddressComponent(term, obj) {
      for (let index = 0; index < obj.length; index++) {
        if (obj[index].types.indexOf(term) > -1) {
          //console.log(obj[index]);
          return obj[index].long_name;
        }
      }
      return null;
  }
  
function CodeAddress(latlng, geocoder) {
    var _add = {}
    return new Promise(function(resolve, reject) {
       geocoder.geocode( { 'location': latlng}, function(results, status) {
        if (status === 'OK') {
          var r = results[0].address_components;
          //street address
          _add.StreetAddress = GetAddressComponent("street_number", r) + " " + GetAddressComponent("route", r);
          //city
          _add.City = GetAddressComponent("locality", r);
          //state/prov
          _add.State = GetAddressComponent("administrative_area_level_1", r);
          //country
          _add.Country = GetAddressComponent("country", r);
          //zip
          _add.Zip = GetAddressComponent("postal_code", r);
          resolve(_add);
        } else {
          alert('Geocode was not successful for the following reason: ' + status);
        }
      });
    });
  }

export function GetLocation(callback, scope) {
  if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition( async (e) => {
        //geo attempt
      var ad = await ReverseGeoLookup({lat:e.coords.latitude, lng:e.coords.longitude});
      var obj = {
          "type":"place", 
          val: {
              address: ad,
              accuracy: e.coords.accuracy,
              altitude: e.coords.altitude,
              altitudeAccuracy: e.coords.altitudeAccuracy,
              heading: e.coords.header,
              latitude: e.coords.latitude,
              longitude: e.coords.longitude,
              speed: e.coords.speed
          },
          time: e.timestamp
      }
      if (scope) {
        callback(obj, scope);
      } else {
        callback(obj);
      }
      
      });
  } else {
      alert("Geolocation is not supported by this browser.");
  }
}